<script lang="ts">
    import { push } from "svelte-spa-router";
    import Atopic from "../lib/Atopic.svelte";
    import BottomNavigation from "../lib/BottomNavigation.svelte";

    import {
        atopics,
        getAtopicByID,
        getRandomAtopic,
        isAtopicMatchingFilter,
        currentDurationFilter,
        currentLocationFilter,
    } from "../stores/atopic-store";
    import TopNavigation from "../lib/AtopicTopNavigation.svelte";
    import NotFound from "./NotFound.svelte";
    import Button from "../lib/Button.svelte";
    import Shuffle from "../lib/Shuffle.icon.svelte";
    import { ditch } from "../transitions/ditch";
    import Image from "../lib/Image.svelte";
    import { atopicProgressState, backgroundAudioMuted } from "../stores/progress-store";
    import { tweened } from "svelte/motion";

    export let params: { atopicid: string; face: string };

    let handleReactionUpload: (e: Event) => void;
    let noNewAtopicFound = false;
    $: above = $atopicProgressState === "started";
    $: currentAtopic = getAtopicByID(params.atopicid, $atopics);
    $: onFilterChange(), $currentDurationFilter, $currentLocationFilter, currentAtopic;
    function onFilterChange() {
        let currentAtopicNotMatchingFilter = !isAtopicMatchingFilter(
            currentAtopic,
            $currentDurationFilter,
            $currentLocationFilter
        );
        if (currentAtopicNotMatchingFilter || noNewAtopicFound) {
            handleDitch(noNewAtopicFound);
        }
    }

    async function handleDitch(includeCurrentAtopic: boolean = false) {
        const randomAtopic = getRandomAtopic(includeCurrentAtopic ? undefined : currentAtopic);
        if (randomAtopic) {
            noNewAtopicFound = false;
            await push("/atopic/" + randomAtopic.id);
        } else {
            noNewAtopicFound = true;
        }
    }

    $: currentFace = params.face || "title";

    const volume = tweened(1, { duration: 1000 });
    let paused = true;
    let currentTime = 0;
    let audio: HTMLAudioElement | null;

    function changeVolume() {
        if (!$backgroundAudioMuted && $atopicProgressState === "started") {
            volume.set(1);
        } else {
            volume.set(0);
        }
    }

    function startStop(volume: number) {
        if (volume > 0) {
            paused = false;
        } else {
            paused = true;
            // currentTime = 0;
        }
    }

    $: changeVolume(), $backgroundAudioMuted, $atopicProgressState;
    $: startStop($volume);
    // $: console.log(paused ? "audio stopped" : "audio started");
    $: if (audio) {
        audio.volume = $volume;
    }
</script>

<!-- preload images -->
{#if $atopics}
    {#each $atopics as atopic}
        {#if atopic?.coverImage}
            <Image image={atopic.coverImage} preload />
        {/if}
    {/each}
{/if}

<!-- preload background audio -->
{#if currentAtopic?.backgroundAudio}
    <audio
        preload="auto"
        autoplay={false}
        muted={false}
        loop={true}
        bind:paused
        bind:currentTime
        bind:this={audio}
    >
        <source src={currentAtopic.backgroundAudio.url} type={currentAtopic.backgroundAudio.mime} />
    </audio>
{/if}
{#if !currentAtopic}
    <NotFound />
{:else}
    {#if noNewAtopicFound}
        <div class="not-found-with-filter">Kein Atopic für deine Filter gefunden!</div>
    {:else}
        <TopNavigation
            atopic={currentAtopic}
            face={currentFace}
            above={!above}
            on:reactionUpload={handleReactionUpload}
        />
        {#key currentAtopic.id}
            <div
                in:ditch={{ inOut: true }}
                out:ditch={{ inOut: false }}
                id={currentAtopic.id}
                class:above
            >
                <Atopic
                    atopic={currentAtopic}
                    face={currentFace}
                    {handleDitch}
                    bind:handleReactionUpload
                />
            </div>
        {/key}
    {/if}
    <BottomNavigation above={!above}>
        <Button on:click={() => handleDitch()} glyph><Shuffle /></Button>
    </BottomNavigation>
{/if}

<style>
    div {
        position: absolute;
        top: 50vh;
        left: 50vw;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
    }

    div.above {
        z-index: 5;
    }

    .not-found-with-filter {
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) rotateZ(1deg);
        /* text-align: center; */
        color: var(--color-inactive);
        z-index: 0;
    }
</style>
