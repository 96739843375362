<script lang="ts">
    import watchMedia from "svelte-media";
    import { isTouchDevice } from "../util/is-touch-device";

    export function isWebWorkerSupported() {
        const supportsWasm = WebAssembly && typeof WebAssembly.instantiate === "function";
        const supportsUserMediaAPI =
            navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === "function";
        const isBrowserSupported = supportsWasm && supportsUserMediaAPI;
        return isBrowserSupported;
    }
    const matches = watchMedia({
        pwa: "(display-mode: standalone)",
        ready: "(min-width: 1px)",
    });

    // let dev = window.location.origin.startsWith("http://localhost");
    let dev = false;
    let correctUrl = window.location.host.startsWith("app.");

    $: supported = isWebWorkerSupported();
    $: pwa = $matches.pwa && correctUrl && isTouchDevice();
</script>

{#if supported}
    {#if $matches.ready}
        {#if pwa || dev}
            <slot />
        {:else}
            <slot name="not-supported-pwa" />
        {/if}
    {/if}
{:else}
    <slot name="not-supported" />
{/if}
