import { derived, writable, get, type Readable, type Writable } from "svelte/store";
import type { ApiData } from "../types/ApiData";
import type { Atopic, LocalAtopic } from "../types/Atopic";
import * as localforage from "localforage";
import { userId } from "./user-store";
import type { AtopicList } from "../types/List";

// state

export const currentLocale = writable<"de" | "en">("de");

export const DEFAULT_LOCATION_FILTER = "everywhere";
export const currentDurationFilter = writable<string | undefined>();
export const currentLocationFilter = writable<string>(DEFAULT_LOCATION_FILTER);

export const doneFilter = writable<boolean>(false);
export const likedFilter = writable<boolean>(false);

// api data

export function updateData(data: ApiData) {
    apiData.set(data);
}
const apiData = writable<ApiData>();
const localAtopics = writable<Map<string, Partial<LocalAtopic>>>(new Map());

export const sharedLists = writable<Map<string, AtopicList>>(new Map());

(async () => {
    const map: Map<string, Atopic> = new Map(JSON.parse(await localforage.getItem("atopics")));
    if (map.size > 0) localAtopics.set(map);
})();

localAtopics.subscribe((_localAtopics) => {
    // @ts-expect-error
    const serializedData = JSON.stringify([..._localAtopics.entries()]);
    if (_localAtopics.size > 0) {
        localforage.setItem("atopics", serializedData);
    }
});

export const like = async (atopicId: string, like: boolean) => {
    localAtopics.update((_localAtopics) => {
        const atopic: Partial<LocalAtopic> = _localAtopics.get(atopicId);
        _localAtopics.set(atopicId, {
            ...atopic,
            liked: like,
        });
        return _localAtopics;
    });

    await fetch(`/api/de/pages/${atopicId}/like`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify({
            like,
            userId,
        }),
    });
};

export const finish = (atopicId: string) => {
    localAtopics.update((_localAtopics) => {
        const atopic: Partial<LocalAtopic> = _localAtopics.get(atopicId);
        _localAtopics.set(atopicId, {
            ...atopic,
            done: true,
        });
        return _localAtopics;
    });
};

export const atopics: Readable<Atopic[]> = derived(
    [apiData, localAtopics],
    ([apiData, localAtopic]) => {
        return apiData?.atopics.map((atopic) => ({
            ...atopic,
            // default values
            liked: false,
            done: false,
            ...localAtopic.get(atopic.id),
        }));
    }
);

export const atopicsFiltered = derived(
    [currentDurationFilter, currentLocationFilter, likedFilter, doneFilter],
    ([currentDurationFilter, currentLocationFilter, likedFilter, doneFilter]) => {
        return (
            !!currentDurationFilter ||
            currentLocationFilter !== DEFAULT_LOCATION_FILTER ||
            likedFilter ||
            doneFilter
        );
    }
);

export const myAtopics: Readable<Atopic[]> = derived(
    [atopics, currentDurationFilter, currentLocationFilter, likedFilter, doneFilter],
    ([atopics, currentDurationFilter, currentLocationFilter, likedFilter, doneFilter]) => {
        return atopics?.filter(
            (atopic) =>
                (atopic.done || atopic.liked) &&
                (!doneFilter || atopic.done) &&
                (!likedFilter || atopic.liked) &&
                isAtopicMatchingFilter(atopic, currentDurationFilter, currentLocationFilter)
        );
    }
);

export const durations = derived(apiData, (apiData) => {
    return Object.entries(apiData?.durations).map(([key, value]) => ({ key, value }));
});

export const locations = derived(apiData, (apiData) => {
    return Object.entries(apiData?.locations).map(([key, value]) => ({ key, value }));
});

// functions

export const isAtopicMatchingFilter = (
    atopic: Atopic | undefined,
    durationFilter: string | undefined,
    locationFilter: string
) => {
    return (
        (durationFilter === undefined || atopic.duration === durationFilter) &&
        (locationFilter === DEFAULT_LOCATION_FILTER ||
            atopic?.location === DEFAULT_LOCATION_FILTER ||
            atopic?.location === locationFilter)
    );
};

export const getRandomAtopic = (currentAtopic?: Atopic, __atopics = get(atopics)) => {
    const df = get(currentDurationFilter);
    const lf = get(currentLocationFilter);

    let _atopics = __atopics.filter(
        (atopic) =>
            atopic.id !== currentAtopic?.id &&
            isAtopicMatchingFilter(atopic, df, lf) &&
            !atopic.excludeFromRecommendation &&
            !atopic.done
    );

    // filter without regarding status = done
    if (_atopics.length === 0) {
        _atopics = get(atopics).filter(
            (atopic) => atopic.id !== currentAtopic?.id && isAtopicMatchingFilter(atopic, df, lf)
        );
    }

    if (_atopics.length === 0) return undefined;
    return _atopics[Math.floor(Math.random() * _atopics.length)];
};

export const getAtopicByID = (id: string, _atopics: Atopic[]) => {
    return _atopics?.find((a) => a.id === id) || undefined;
};
