<script>
    import { fly } from "svelte/transition";
    export let above = true;
</script>

<nav transition:fly={{ y: -30, duration: 200, delay: 400 }} class:above>
    <slot />
</nav>

<style>
    nav {
        position: absolute;
        top: env(safe-area-inset-top, 0);
        left: 0;
        width: 100%;
        padding: 0.5rem;
    }

    nav.above {
        z-index: 2;
    }
</style>
