<script lang="ts">
    import { fade } from "svelte/transition";
    import AtopolyAtopic from "../lib/AtopolyAtopic.svelte";
    import BottomNavigation from "../lib/BottomNavigation.svelte";
    import Button from "../lib/Button.svelte";
    import DataLoader from "../lib/DataLoader.svelte";
    import Stack from "../lib/Stack.svelte";
    import { atopics } from "../stores/atopic-store";
    import { ditch } from "../transitions/ditch";
    import type { Atopic } from "../types/Atopic";

    let data: any;
    let atopic: Atopic | undefined;
    let face: string = "title";
    let selectedAtopics: Atopic[] | undefined;
    let selectedTopic: string | undefined;
    $: if (selectedTopic && data) {
        selectedAtopics = data.topics?.find((t) => t.id === selectedTopic)?.atopics.map(mapAtopics);
    } else {
        atopic = undefined;
    }
    $: getRandomOnChange(), selectedAtopics;

    function getRandomOnChange() {
        if (selectedAtopics && selectedAtopics.length > 1) {
            face = "title";
            atopic = getRandomAtopic(atopic, selectedAtopics);
            console.log("selected atopics changed");
        } else {
            atopic = undefined;
        }
    }

    function mapAtopics({ id }: { id: string }) {
        return $atopics.find((a) => a.id === id);
    }

    function filterEmpty(atopic: Atopic | undefined) {
        return atopic !== undefined;
    }

    function handleStackClick(topic: string) {
        selectedTopic = topic;
    }

    function getRandomAtopic(_atopic: Atopic | undefined, _atopics: Atopic[]) {
        let atopics = _atopics;
        if (!!_atopic) {
            atopics = _atopics.filter((a) => a.id !== _atopic.id);
        }
        if (atopics.length === 0) return undefined;
        return atopics[Math.floor(Math.random() * atopics.length)];
    }

    function handleDitch() {
        face = "title";
        if (selectedAtopics && selectedAtopics.length > 1) {
            atopic = getRandomAtopic(atopic, selectedAtopics);
        }
    }

    $: console.log("change", selectedTopic, atopic?.id);

    function handleAtopicClick() {
        if (face === "title") face = "instruction";
        else face = "title";
    }

    function handleBack() {
        selectedTopic = undefined;
    }
</script>

<DataLoader url="/api/de/atopoly" on:data={(e) => (data = e.detail.data)}>
    {#key atopic?.id}
        {#if !atopic}
            <div class="container">
                <header transition:fade={{ duration: 300, delay: 200 }}>
                    <h1>Atopoly</h1>
                    {#if data.introduction}<p>{data.introduction}</p>{/if}
                </header>
                {#each data.topics as atopoly (atopoly.id)}
                    {@const atopics = atopoly.atopics.map(mapAtopics).filter(filterEmpty)}
                    {#if atopoly.atopics.length > 1}
                        {@const firstAtopic = { ...atopics[0], title: atopoly.title }}
                        <div class="atopoly-container">
                            <Stack
                                atopic={firstAtopic}
                                on:click={() => handleStackClick(atopoly.id)}
                            />
                            {#if atopoly.description}
                                <p transition:fade={{ duration: 300, delay: 10 }}>
                                    {atopoly.description}
                                </p>
                            {/if}
                        </div>
                    {/if}
                {/each}
            </div>
        {:else}
            <div
                in:ditch={{ inOut: true }}
                out:ditch={{ inOut: false }}
                class="atopic-container"
                id={atopic.id}
            >
                <AtopolyAtopic
                    {atopic}
                    {face}
                    on:click={handleAtopicClick}
                    on:ditch={handleDitch}
                />
            </div>
        {/if}
    {/key}
</DataLoader>

<BottomNavigation>
    <svelte:fragment slot="left">
        {#if atopic}
            <Button glyph on:click={handleBack}>←</Button>
        {/if}
    </svelte:fragment>
</BottomNavigation>

<style>
    div.atopic-container {
        position: absolute;
        top: 50vh;
        left: 50vw;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
    }

    div.atopoly-container {
        margin-bottom: 4rem;
    }

    div.atopoly-container > p {
        text-align: left;
        color: var(--color-inactive);
        padding: 1rem 0.5rem 0.5rem 0.5rem;
    }
    p {
        padding: 0.5rem;
    }

    header {
        margin-bottom: 2rem;
    }
    h1 {
        font-size: 3rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .container {
        padding: calc(env(safe-area-inset-top) + 0rem) 0.5rem 0.5rem 0.5rem;
        line-height: 1.3em;
        overflow: scroll;
        overflow-x: hidden;
        width: 100%;
        height: 100%;
    }
</style>
