<script lang="ts">
    import { spring } from "svelte/motion";
    import { withPrevious } from "svelte-previous";
    import morph from "../transitions/morph";
    import flip from "../transitions/flip";
    import { onDestroy, tick } from "svelte";

    export let handleDitch: () => void;
    export let isDitchable = () => true;
    export let isDraggable = (page: string) => true;
    export let morphable = false;

    let flipAnimationRunning = false;
    let width: number, height: number;

    let dragOffsetX: number, dragOffsetY: number;
    let oldScreenX: number, oldScreenY: number;
    let movementX: number, movementY: number, movementR: number;
    const initialAngle = createRandomAngle();
    let position = spring(
        { x: 0, y: 0 },
        {
            damping: 0.9,
            stiffness: 0.3,
        }
    );
    let angle = spring(initialAngle, {
        stiffness: 0.2,
        damping: 0.7,
    });
    let touchMoving = false;
    let timeoutId: number;
    let hidden = false;

    export let currentFace = "title";

    const [currentWidth, oldWidth] = withPrevious(0, { requireChange: false });
    const [currentHeight, oldHeight] = withPrevious(0, { requireChange: false });

    // also add corresponding width, height as deps
    $: ($currentWidth = width), height;
    $: ($currentHeight = height), width;
    $: initialScaleX = ($oldWidth || $currentWidth) / $currentWidth;
    $: initialScaleY = ($oldHeight || $currentHeight) / $currentHeight;

    function handlePointerDown(e: PointerEvent) {
        if (!isDraggable(currentFace)) return;
        if (!e.isPrimary) return;
        dragOffsetX = e.offsetX;
        dragOffsetY = e.offsetY;
        oldScreenX = e.screenX;
        oldScreenY = e.screenY;
    }

    function createRandomAngle(span = 5) {
        return (-1 + Math.random() * 2) * span;
    }
    function setRandomAngle() {
        angle.set(createRandomAngle(), {
            hard: true,
        });
    }

    async function handlePointerMove(e: PointerEvent) {
        if (!e.isPrimary) return;
        if (!isDraggable(currentFace)) return;
        movementX = e.screenX - oldScreenX;
        movementY = e.screenY - oldScreenY;
        const xRotationFactor = -1 + (dragOffsetX / width) * 2;
        const xRotation = xRotationFactor * movementY;
        const yRotationFactor = (-1 + (dragOffsetY / height) * 2) * -1;
        const yRotation = yRotationFactor * movementX;
        movementR = xRotation * 0.4 + yRotation * 0.3;

        await tick();

        position.update(
            (current) => ({
                x: current.x + movementX * (1 - Math.abs(yRotationFactor * 0.3)),
                y: current.y + movementY * (1 - Math.abs(xRotationFactor * 0.4)),
            }),
            {
                hard: true,
            }
        );
        angle.update((prevRotation) => prevRotation + movementR || 0);
        touchMoving = true;
        oldScreenX = e.screenX;
        oldScreenY = e.screenY;
    }

    function shouldCardBeDitched(screenX: number, screenY: number) {
        if (!isDitchable()) return false;
        const hasEnoughSpeed = Math.sqrt(movementX * movementX + movementY * movementY) > 2;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const threshold = 50;
        const isCloseToScreenBorder =
            screenX < threshold ||
            screenX > screenWidth - threshold ||
            screenY < threshold ||
            screenY > screenHeight - threshold;
        const hasMoved = Math.sqrt($position.x ** 2 + $position.y ** 2) > 100;
        return isCloseToScreenBorder && hasMoved && hasEnoughSpeed;
    }

    function handlePointerCancel(e: PointerEvent) {
        if (!isDraggable(currentFace)) return;
        if (!e.isPrimary) return;
        touchMoving = false;
        setTimeout(() => {
            position.set({
                x: 0,
                y: 0,
            });
            angle.set(initialAngle);
        }, 200);
    }

    function handlePointerUp(e: PointerEvent) {
        if (!e.isPrimary) return;
        if (!touchMoving) return;
        if (!isDraggable(currentFace)) return;

        if (shouldCardBeDitched(e.screenX, e.screenY)) {
            position.update((pos) => ({
                x: pos.x * 2.5,
                y: pos.y * 2.5,
            }));
            angle.update((a) => a * 1.2);
            timeoutId = window.setTimeout(() => {
                hidden = true;
                handleDitch();
            }, 400);
        } else {
            if (Math.sqrt($position.x ** 2 + $position.y ** 2) > 30) {
                angle.set(createRandomAngle());
            }
            position.set({ x: 0, y: 0 });
        }
        touchMoving = false;
    }

    onDestroy(() => {
        window.clearTimeout(timeoutId);
    });
</script>

{#if !hidden}
    <div
        class="card-position-wrapper"
        style={`--x:${$position.x}px; --y:${$position.y}px; --a:${$angle}deg`}
    >
        {#key currentFace}
            <div
                class="card-rotation-wrapper"
                class:animation-running={flipAnimationRunning}
                in:flip|local={{ out: false }}
                out:flip|local={{ out: true }}
                on:introend={() => {
                    flipAnimationRunning = false;
                }}
                on:outrostart={() => {
                    flipAnimationRunning = true;
                }}
                on:outroend={() => {
                    setRandomAngle();
                }}
            >
                <!-- <div class="card face" style={`--width: ${width}; --height:${height}`} /> -->
                <div
                    bind:offsetWidth={width}
                    bind:offsetHeight={height}
                    on:pointerdown={handlePointerDown}
                    on:pointermove={handlePointerMove}
                    on:pointerup={handlePointerUp}
                    on:pointercancel={handlePointerCancel}
                    class="card content-wrapper"
                >
                    {#if morphable}
                        {#key [$currentWidth, $currentHeight]}
                            <div class="content" in:morph|local={{ initialScaleX, initialScaleY }}>
                                <slot width={$currentWidth} height={$currentHeight} />
                            </div>
                        {/key}
                    {:else}
                        <div class="content">
                            <slot width={$currentWidth} height={$currentHeight} />
                        </div>
                    {/if}
                </div>
                {#if $$slots["nav-top"]}
                    <div
                        class="nav top"
                        style={`transform: translateX(calc(${
                            $currentWidth / 2
                        }px - 100%)) translateY(${$currentHeight / -2}px)`}
                    >
                        <slot name="nav-top" />
                    </div>
                {/if}
                {#if $$slots["nav-bottom"]}
                    <div
                        class="nav bottom"
                        style={`transform: translateX(-50%) translateY(${$currentHeight / 2}px)`}
                    >
                        <slot name="nav-bottom" />
                    </div>
                {/if}
            </div>
        {/key}
    </div>
{/if}

<style>
    .card-position-wrapper {
        perspective-origin: center center;
        perspective: calc(80vw * 11);
        will-change: transform;
        transform: translateX(var(--x)) translateY(var(--y)) rotateZ(var(--a));
        z-index: 1;
    }
    .card-rotation-wrapper {
        transform-style: preserve-3d;
        backface-visibility: hidden;
        will-change: transform;
    }
    .content-wrapper {
        transform: translate(-50%, -50%);
    }

    .card {
        position: absolute;
        top: 0;
        left: 0;
    }

    .nav {
        position: absolute;
        z-index: -1;
    }

    .nav.bottom {
        top: 0.666rem;
    }

    .nav.top {
        bottom: 0.5rem;
    }

    .card-rotation-wrapper:not(.animation-running) .nav {
        transition: transform 200ms 0ms;
    }

    /* .face {
		width: 1px;
		height: 1px;
		background: #fff;
		transform: scaleX(var(--width)) scaleY(var(--height));
	} */
</style>
