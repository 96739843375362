<script lang="ts">
    import { linear } from "svelte/easing";
    import { tweened } from "svelte/motion";
    import { fade } from "svelte/transition";
    import {
        fastForward,
        atopicProgressState,
        progress,
        MAX_DURATION,
        timePassed,
        completeTask,
        timeOffset,
        abort,
        isFastForwarding,
    } from "../stores/progress-store";

    const opacity = tweened(1, { duration: 0 });
    $: {
        opacity.set(1 - $progress * 0.5, {
            duration: 1000,
            easing: linear,
        });
    }

    $: if ($atopicProgressState === "started") opacity.set(1, { duration: 0 });

    function handleCancel(e: Event) {
        if ($atopicProgressState !== "started") return;
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
    }

    async function handleClick(e: Event) {
        if ($atopicProgressState !== "started") return;
        e.preventDefault();
        e.stopPropagation();
        fastForward();

        if ($timePassed + $timeOffset > MAX_DURATION && !$isFastForwarding) {
            completeTask();
        }
        // const isDone = true;
    }
</script>

<div
    on:pointerdown={handleCancel}
    on:pointermove={handleCancel}
    on:pointerup={handleCancel}
    on:click={handleClick}
    on:keydown={handleCancel}
>
    <slot />
</div>
{#if $atopicProgressState === "started"}
    <div
        class="overlay"
        style={`--opacity: ${$opacity}`}
        on:pointerdown={handleCancel}
        on:pointermove={handleCancel}
        on:pointerup={handleCancel}
        on:keydown={handleCancel}
        on:click={handleClick}
        in:fade={{ duration: 300 }}
        out:fade={{ duration: 500 }}
    />
    <!-- <div class="progress" style={`--scale: ${scale};`} /> -->
{/if}

<style>
    div.progress {
        height: 0.2rem;
        width: calc(95vw / 100);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background: var(--color-inactive);
        transform: scaleX(var(--scale, 0));
    }
    div.overlay {
        position: fixed;
        top: -50vh;
        left: -50vw;
        right: -50vw;
        bottom: -50vh;
        background: white;
        z-index: -1;
        opacity: var(--opacity, 0);
    }
</style>
